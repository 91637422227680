body {
  margin-left: 25vw;
  margin-right: 25vw;
  font-family: monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: floralwhite;
  background-color: #7B1E7A;
}

h1 {
  padding-top: 100px;
  padding-bottom: 50px;
  text-align: center;
  font-size: 42px;
}

h2 {
  padding-top: 25px;
  font-size: 25px;
}

h3 {
  font-weight: normal;
}

h4 {
  text-align: center;
  font-size: 18px;
  padding-top: 100px;
}

p {
  font-size: 18px;
}

a {
  color: floralwhite;
  text-decoration: none;
}

.justify {
  white-space: pre;
}

.field {
  margin-top: 20px;
  font-size: 18px;
  font-family: monospace;
}

.large-field {
  height: 200px;
}

.right-aligned {
  text-align: right;
}

.italics {
  font-style: italic;
}

a:hover {
  font-style: italic; 
}

input {
  width: 40vw;
  font-size: 16px;
  font-family: monospace;
}

textarea {
  width: calc(40vw + 2px);
  height: 180px;
  font-size: 16px;
  font-family: monospace;
}

button {
  font-size: 16px;
  font-family: monospace;
  background-color: floralwhite;
  border: 0.1em solid floralwhite;
  color: #7b1e7a;
  border-radius: 0.12em;
  transition: all 0.2s;
  margin-left: 8em;
}

button:hover {
  background-color: #7b1e7a;
  color: floralwhite;
}